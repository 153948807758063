import * as React from "react";
import { CartItem } from "../../../types/shopping-cart";

export type CartContext = {
  loading: boolean;
  items: CartItem[];
  updateCart: (items: CartItem[]) => void;
  addToCart: (item: CartItem) => void;
  removeFromCart: (item: { collectionId: string; tokenId: string }) => void;
  performCartCheck: () => Promise<CartItem[] | undefined>;
};

export const defaultContextValue: CartContext = {
  loading: true,
  items: [],
  updateCart: () => undefined,
  addToCart: () => undefined,
  removeFromCart: () => undefined,
  performCartCheck: async () => undefined,
};

export const CartContext =
  React.createContext<CartContext>(defaultContextValue);
