import Web3 from "web3";
import { web3Utils } from "../../web3/web3-utils";
import { getPaymentTokenContract } from "../payment-token-contract";

interface TokenBalanceInput {
  chainId: number;
  web3: Web3;
  userAccount: string;
}

export const getPaymentTokenBalance = async ({
  chainId,
  web3,
  userAccount,
}: TokenBalanceInput) => {
  const tokenContract = getPaymentTokenContract({ chainId, web3 });

  if (!tokenContract) {
    return null;
  }

  try {
    const balanceOf = await tokenContract.methods.balanceOf(userAccount).call();
    return Number(web3Utils.fromWei(balanceOf));
  } catch (error) {
    console.error("Failed to get token balance", error);
    return null;
  }
};
