import * as React from "react";
import { emptyFeatureConfig, FeaturesConfig } from "../utils/features-config";

export interface SiteContext {
  isMobile: boolean;
  isDarkMode: boolean;
  featureConfigs: FeaturesConfig;
  setDarkmode: (isDarkMode: boolean) => void;
}

const defaultContextValue: SiteContext = {
  isMobile: false,
  isDarkMode: false,
  featureConfigs: emptyFeatureConfig,
  setDarkmode: () => undefined,
};

export const SiteContext =
  React.createContext<SiteContext>(defaultContextValue);
