import Web3 from "web3";
import { ChainId } from "../../../types/web3";

// TODO: Add all the chains
export const RPC_PROVIDER: Record<number, string> = {
  [ChainId.BSC]: "https://bsc-dataseed1.defibit.io/",
  [ChainId.ETH]:
    "https://mainnet.infura.io/v3/24fc0fe66a1f41bfb30983aa3f498565",
  [ChainId.MATIC]: "https://polygon-rpc.com/",
  [ChainId.FTM]: "https://rpc.ftm.tools",
  [ChainId.AVAX]: "https://api.avax.network/ext/bc/C/rpc",
  [ChainId.ONE]: "https://api.harmony.one",
};

interface DataWeb3ByChainIdInput {
  chainId?: number;
}

export const getDataWeb3ByChainId = ({ chainId }: DataWeb3ByChainIdInput) => {
  const rpcUrl = chainId ? RPC_PROVIDER[chainId] : undefined;
  if (!rpcUrl) {
    return undefined;
  }

  const provider = new Web3.providers.HttpProvider(rpcUrl);
  return new Web3(provider);
};

interface CollectionWeb3Input {
  connectedWeb3?: Web3;
  collectionChainId: number;
}

export const getCollectionWeb3 = async ({
  connectedWeb3,
  collectionChainId,
}: CollectionWeb3Input) => {
  const dataWeb3 = getDataWeb3ByChainId({
    chainId: collectionChainId,
  });

  const connectedChainId = await connectedWeb3?.eth.getChainId();
  const isMatchingChain = connectedChainId === collectionChainId;

  const hasFreeWeb3 =
    (isMatchingChain && !!connectedWeb3) || collectionChainId !== ChainId.ETH;
  const web3ToUse = (isMatchingChain && connectedWeb3) || dataWeb3;

  return { collectionWeb3: web3ToUse, isFree: hasFreeWeb3 };
};
