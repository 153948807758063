import Web3 from "web3";
import { web3Utils } from "./web3-utils";

interface GetBalanceInput {
  web3: Web3;
  userAccount: string;
}

export const getBalance = async ({ web3, userAccount }: GetBalanceInput) => {
  try {
    const ethBalanceInWei = await web3.eth.getBalance(userAccount);
    const ethBalance = Number(web3Utils.fromWei(ethBalanceInWei));

    return { ethBalanceInWei, ethBalance };
  } catch (err) {
    console.error("Failed to get user wallet balance:", err);
    return null;
  }
};
