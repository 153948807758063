import Web3 from "web3";
import {
  CartItem,
  PurchaseCheckResult,
} from "../../../../../types/shopping-cart";
import { getBalance } from "../../../web3/wallet-balance";
import { getERC721ListingsV3Contract } from "../marketplace-v3-contract";

interface PurchaseTokenInput {
  erc721Address: string;
  tokenId: string;
  value: string;
}

interface CheckForPurchaseInput {
  contractAddress: string;
  web3: Web3;
  tokens: CartItem[];
  buyer: string;
  chainId: number;
}

const messageMapping: Record<string, string | undefined> = {
  "listing is not valid": "Listing is no longer valid",
  "buying below asking price": "Listing is no longer valid",
  "insufficient fund": undefined,
};

export const checkTokensForPurchase = async ({
  contractAddress,
  web3,
  tokens,
  buyer,
  chainId,
}: CheckForPurchaseInput): Promise<CartItem[]> => {
  const contract = getERC721ListingsV3Contract({
    contractAddress,
    web3,
  });

  try {
    const tokensToCheck: PurchaseTokenInput[] = tokens.map((t) => ({
      erc721Address: t.collectionInfo.address,
      tokenId: t.tokenId,
      value: t.priceInWei,
    }));
    const userBalance = await getBalance({ web3, userAccount: buyer });

    if (userBalance) {
      const checkResults: PurchaseCheckResult[] = await contract.methods
        .checkTokensForPurchase(
          tokensToCheck,
          buyer,
          userBalance.ethBalanceInWei
        )
        .call();

      const tokensWithResult: CartItem[] = [];

      tokens.forEach((token, index) => {
        const purchaseCheckResult = checkResults[index];
        const isMatchingChain = token.collectionInfo.chainId === chainId;

        if (
          !isMatchingChain ||
          (purchaseCheckResult?.message !== "buyer cannot be owner" &&
            purchaseCheckResult?.message !== "listing is not valid")
        ) {
          tokensWithResult.push({
            ...token,
            purchaseCheckResult:
              purchaseCheckResult && isMatchingChain
                ? {
                    isValid: purchaseCheckResult.isValid,
                    message: messageMapping[purchaseCheckResult.message],
                  }
                : undefined,
          });
        }
      });

      return tokensWithResult;
    }
  } catch (error) {
    console.error(
      "Failed to check tokens for purchase V3",
      contractAddress,
      error
    );
  }
  return tokens;
};
