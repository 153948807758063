import LogoBlack from "./logo-black.svg";
import LogoWhite from "./logo-white.svg";
import LogoIconBlack from "./logo-icon-black.svg";
import LogoIconWhite from "./logo-icon-white.svg";
import LogoIconWhiteCutout from "./logo-icon-white-cutout.svg";
import NftkeyAvatarBlack from "./nftkey-avatar-black.svg";
import NftkeyAvatarWhite from "./nftkey-avatar-white.svg";

import faviconImage from "./favicon.png";
import SocialShareImage from "./social-share.png";
import EverythingIsFine from "./404-everything-is-fine.png";
import Astronaut from "./astronaut.svg";
import Danger from "./danger.svg";
import BurntBackground from "./burnt-background.png";

import Close from "./icons/close-icon.svg";
import CloseBlack from "./icons/close-icon-black.svg";
import CloseWhite from "./icons/close-icon-white.svg";
import ArrowBlack from "./icons/arrow-icon-black.svg";
import ArrowWhite from "./icons/arrow-icon-white.svg";
import Light from "./icons/light-icon.svg";
import Moon from "./icons/moon-icon.svg";
import ChevronBlack from "./icons/chevron-icon-black.svg";
import ChevronWhite from "./icons/chevron-icon-white.svg";
import CheckBlack from "./icons/check-icon-black.svg";
import CheckWhite from "./icons/check-icon-white.svg";
import FilterBlack from "./icons/filter-icon-black.svg";
import FilterWhite from "./icons/filter-icon-white.svg";
import LinkBlack from "./icons/link-icon-black.svg";
import LinkWhite from "./icons/link-icon-white.svg";
import SearchBlack from "./icons/search-icon-black.svg";
import SearchWhite from "./icons/search-icon-white.svg";
import Search2Black from "./icons/search-2-icon-black.svg";
import Search2White from "./icons/search-2-icon-white.svg";
import FireBlack from "./icons/fire-icon-black.svg";
import FireWhite from "./icons/fire-icon-white.svg";
import BananaBlack from "./icons/banana-icon-black.svg";
import BananaWhite from "./icons/banana-icon-white.svg";
import StatsBlack from "./icons/activity-icon-black.svg";
import StatsWhite from "./icons/activity-icon-white.svg";
import InfoBlack from "./icons/info-icon-black.svg";
import InfoWhite from "./icons/info-icon-white.svg";
import ListBlack from "./icons/list-icon-black.svg";
import ListWhite from "./icons/list-icon-white.svg";
import GraphBlack from "./icons/graph-icon-black.svg";
import GraphWhite from "./icons/graph-icon-white.svg";
import BadgeBlack from "./icons/badge-icon-black.svg";
import BadgeWhite from "./icons/badge-icon-white.svg";
import ShareBlack from "./icons/share-icon-black.svg";
import ShareWhite from "./icons/share-icon-white.svg";
import CopyBlack from "./icons/copy-icon-black.svg";
import CopyWhite from "./icons/copy-icon-white.svg";
import CartBlack from "./icons/cart-icon-black.svg";
import CartWhite from "./icons/cart-icon-white.svg";
import ConvertBlack from "./icons/convert-icon-black.svg";
import ConvertWhite from "./icons/convert-icon-white.svg";
import DisconnectBlack from "./icons/disconnect-icon-black.svg";
import DisconnectWhite from "./icons/disconnect-icon-white.svg";
import CollectionBlack from "./icons/collection-icon-black.svg";
import CollectionWhite from "./icons/collection-icon-white.svg";
import MyNftsBlack from "./icons/my-nfts-icon-black.svg";
import MyNftsWhite from "./icons/my-nfts-icon-white.svg";
import TickBlack from "./icons/tick-icon-black.svg";
import TickWhite from "./icons/tick-icon-white.svg";
import FingerprintBlack from "./icons/fingerprint-icon-black.svg";
import FingerprintWhite from "./icons/fingerprint-icon-white.svg";
import GlobeBlack from "./icons/globe-icon-black.svg";
import GlobeWhite from "./icons/globe-icon-white.svg";
import ExplorerBlack from "./icons/explorer-icon-black.svg";
import ExplorerWhite from "./icons/explorer-icon-white.svg";
import WebsiteBlack from "./icons/website-icon-black.svg";
import WebsiteWhite from "./icons/website-icon-white.svg";
import MetadataBlack from "./icons/metadata-icon-black.svg";
import MetadataWhite from "./icons/metadata-icon-white.svg";
import BubbleBlack from "./icons/bubble-icon-black.svg";
import BubbleWhite from "./icons/bubble-icon-white.svg";
import BubbleSolidBlack from "./icons/bubble-solid-icon-black.svg";
import BubbleSolidWhite from "./icons/bubble-solid-icon-white.svg";
import ImageBlack from "./icons/image-icon-black.svg";
import ImageWhite from "./icons/image-icon-white.svg";
import EditBlack from "./icons/edit-icon-black.svg";
import EditWhite from "./icons/edit-icon-white.svg";
import Info from "./icons/info-icon.svg";
import Connection from "./icons/connection-icon.svg";
import ConnectionGrey from "./icons/connection-icon-grey.svg";
import WarningRed from "./icons/warning-icon-red.svg";

import DiscordBlack from "./icons/discord-black.svg";
import DiscordWhite from "./icons/discord-white.svg";
import InstagramBlack from "./icons/instagram-black.svg";
import InstagramWhite from "./icons/instagram-white.svg";
import TwitterBlack from "./icons/twitter-black.svg";
import TwitterWhite from "./icons/twitter-white.svg";
import TelegramBlack from "./icons/telegram-black.svg";
import TelegramWhite from "./icons/telegram-white.svg";
import FacebookBlack from "./icons/facebook-black.svg";
import FacebookWhite from "./icons/facebook-white.svg";
import EmailBlack from "./icons/email-black.svg";
import EmailWhite from "./icons/email-white.svg";
import GitbookBlack from "./icons/gitbook-black.svg";
import GitbookWhite from "./icons/gitbook-white.svg";
import MediumBlack from "./icons/medium-black.svg";
import MediumWhite from "./icons/medium-white.svg";

import PlayBlack from "./icons/play-icon-black.svg";
import PlayWhite from "./icons/play-icon-white.svg";
import PauseBlack from "./icons/pause-icon-black.svg";
import PauseWhite from "./icons/pause-icon-white.svg";

import MetaMask from "./logos/metamask.svg";
import CoinBaseWallet from "./logos/coinbasewallet.svg";
import WalletConnect from "./logos/walletconnect.svg";
import TrustWallet from "./logos/trustwallet.svg";
import ApeSwap from "./logos/apeswap.svg";
import ApeSwapFull from "./logos/apeswap-full.svg";
import WaifusionLoading from "./logos/waifusion-loading.svg";
import LifeLogoBlack from "./logos/life-logo-black.svg";
import LifeLogoWhite from "./logos/life-logo-white.svg";
import GoalWhite from "./logos/goal-white.svg";

import EthLogoBlack from "./chain-logo/eth-black.svg";
import EthLogoWhite from "./chain-logo/eth-white.svg";
import EthLogoColor from "./chain-logo/eth-color.svg";
import BscLogoBlack from "./chain-logo/bsc-black.svg";
import BscLogoWhite from "./chain-logo/bsc-white.svg";
import BscLogoColor from "./chain-logo/bsc-color.svg";
import AvaxLogoBlack from "./chain-logo/avax-black.svg";
import AvaxLogoWhite from "./chain-logo/avax-white.svg";
import AvaxLogoColor from "./chain-logo/avax-color.svg";
import PolygonLogoBlack from "./chain-logo/polygon-black.svg";
import PolygonLogoWhite from "./chain-logo/polygon-white.svg";
import PolygonLogoColor from "./chain-logo/polygon-color.svg";
import FtmLogoBlack from "./chain-logo/ftm-black.svg";
import FtmLogoWhite from "./chain-logo/ftm-white.svg";
import FtmLogoColor from "./chain-logo/ftm-color.svg";
import OneLogoBlack from "./chain-logo/one-black.svg";
import OneLogoWhite from "./chain-logo/one-white.svg";
import OneLogoColor from "./chain-logo/one-color.svg";

import WalletToBscLightMode from "./collection-info/wallet-to-bsc-light-mode.svg";
import WalletToBscDarkMode from "./collection-info/wallet-to-bsc-dark-mode.svg";
import WalletToEthLightMode from "./collection-info/wallet-to-eth-light-mode.svg";
import WalletToEthDarkMode from "./collection-info/wallet-to-eth-dark-mode.svg";

import IdeasBlack from "./life-icons/ideas-icon-black.svg";
import IdeasWhite from "./life-icons/ideas-icon-white.svg";
import RandomBlack from "./life-icons/random-icon-black.svg";
import RandomWhite from "./life-icons/random-icon-white.svg";
import RestartBlack from "./life-icons/restart-icon-black.svg";
import RestartWhite from "./life-icons/restart-icon-white.svg";
import ResetBlack from "./life-icons/reset-icon-black.svg";
import ResetWhite from "./life-icons/reset-icon-white.svg";
import ShrinkBlack from "./life-icons/shrink-icon-black.svg";
import ShrinkWhite from "./life-icons/shrink-icon-white.svg";
import ExpandBlack from "./life-icons/expand-icon-black.svg";
import ExpandWhite from "./life-icons/expand-icon-white.svg";
import EncounterBlack from "./life-icons/encounter-icon-black.svg";
import EncounterWhite from "./life-icons/encounter-icon-white.svg";
import MusicOnBlack from "./life-icons/music-on-icon-black.svg";
import MusicOnWhite from "./life-icons/music-on-icon-white.svg";
import MusicOffBlack from "./life-icons/music-off-icon-black.svg";
import MusicOffWhite from "./life-icons/music-off-icon-white.svg";

import SpunksStep1 from "./spunks/step1.png";
import SpunksStep2 from "./spunks/step2.png";
import SpunksStep3 from "./spunks/step3.png";
import SpunksPlusBlack from "./spunks/plus-black.svg";
import SpunksPlusWhite from "./spunks/plus-white.svg";
import SpunksEqualsBlack from "./spunks/equals-black.svg";
import SpunksEqualsWhite from "./spunks/equals-white.svg";
import SpunksCMYK from "./spunks/cmyk.svg";
import Avatar0xb1 from "./spunks/0xb1.png";
import AvatarSpongenuity from "./spunks/spongenuity.png";

const Icons = {
  Close,
  CloseBlack,
  CloseWhite,
  ArrowBlack,
  ArrowWhite,
  Light,
  Moon,
  ChevronBlack,
  ChevronWhite,
  CheckBlack,
  CheckWhite,
  FilterBlack,
  FilterWhite,
  LinkBlack,
  LinkWhite,
  SearchBlack,
  SearchWhite,
  Search2Black,
  Search2White,
  FireBlack,
  FireWhite,
  BananaBlack,
  BananaWhite,
  StatsBlack,
  StatsWhite,
  InfoBlack,
  InfoWhite,
  ListBlack,
  ListWhite,
  GraphBlack,
  GraphWhite,
  BadgeBlack,
  BadgeWhite,
  ShareBlack,
  ShareWhite,
  CopyBlack,
  CopyWhite,
  CartBlack,
  CartWhite,
  ConvertBlack,
  ConvertWhite,
  DisconnectBlack,
  DisconnectWhite,
  CollectionBlack,
  CollectionWhite,
  MyNftsBlack,
  MyNftsWhite,
  TickBlack,
  TickWhite,
  FingerprintBlack,
  FingerprintWhite,
  GlobeBlack,
  GlobeWhite,
  ExplorerBlack,
  ExplorerWhite,
  WebsiteBlack,
  WebsiteWhite,
  MetadataBlack,
  MetadataWhite,
  BubbleBlack,
  BubbleWhite,
  BubbleSolidBlack,
  BubbleSolidWhite,
  ImageBlack,
  ImageWhite,
  EditBlack,
  EditWhite,
  DiscordBlack,
  DiscordWhite,
  InstagramBlack,
  InstagramWhite,
  TwitterBlack,
  TwitterWhite,
  TelegramBlack,
  TelegramWhite,
  FacebookBlack,
  FacebookWhite,
  GitbookBlack,
  GitbookWhite,
  EmailBlack,
  EmailWhite,
  MediumBlack,
  MediumWhite,
  PlayBlack,
  PlayWhite,
  PauseBlack,
  PauseWhite,
  Info,
  Connection,
  ConnectionGrey,
  WarningRed,
};

const Logos = {
  MetaMask,
  CoinBaseWallet,
  WalletConnect,
  TrustWallet,
  ApeSwap,
  ApeSwapFull,
  WaifusionLoading,
  LifeLogoBlack,
  LifeLogoWhite,
  GoalWhite,
};

const ChainLogos = {
  EthLogoBlack,
  EthLogoWhite,
  EthLogoColor,
  BscLogoBlack,
  BscLogoWhite,
  BscLogoColor,
  AvaxLogoBlack,
  AvaxLogoWhite,
  AvaxLogoColor,
  PolygonLogoBlack,
  PolygonLogoWhite,
  PolygonLogoColor,
  FtmLogoBlack,
  FtmLogoWhite,
  FtmLogoColor,
  OneLogoBlack,
  OneLogoWhite,
  OneLogoColor,
};

const CollectionInfo = {
  WalletToBscLightMode,
  WalletToBscDarkMode,
  WalletToEthLightMode,
  WalletToEthDarkMode,
};

const LifeIcons = {
  IdeasBlack,
  IdeasWhite,
  RandomBlack,
  RandomWhite,
  RestartBlack,
  RestartWhite,
  ResetBlack,
  ResetWhite,
  ShrinkBlack,
  ShrinkWhite,
  ExpandBlack,
  ExpandWhite,
  EncounterBlack,
  EncounterWhite,
  MusicOnBlack,
  MusicOnWhite,
  MusicOffBlack,
  MusicOffWhite,
};

const SpunksImages = {
  SpunksStep1,
  SpunksStep2,
  SpunksStep3,
  SpunksPlusBlack,
  SpunksPlusWhite,
  SpunksEqualsBlack,
  SpunksEqualsWhite,
  SpunksCMYK,
  Avatar0xb1,
  AvatarSpongenuity,
};

export {
  LogoBlack,
  LogoWhite,
  LogoIconBlack,
  LogoIconWhite,
  LogoIconWhiteCutout,
  NftkeyAvatarBlack,
  NftkeyAvatarWhite,
  faviconImage,
  SocialShareImage,
  EverythingIsFine,
  Astronaut,
  Danger,
  BurntBackground,
  Icons,
  Logos,
  ChainLogos,
  CollectionInfo,
  LifeIcons,
  SpunksImages,
};
