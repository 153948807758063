import localForage from "localforage";

export const defaultStorage = localForage.createInstance({
  name: "nftkeyDefault",
});

export const indexDbStorage = localForage.createInstance({
  name: "nftkeyIndexDB",
});

export const persistStorage = localForage.createInstance({
  name: "nftkeyPersistStorage",
});

export const getDefaultStorageItem = async <T>(
  key: string
): Promise<T | null> => {
  await defaultStorage.setDriver(localForage.LOCALSTORAGE);
  const result = await defaultStorage.getItem<T>(key);
  return result;
};

export const setDefaultStorageItem = async <T>(key: string, value: T) => {
  try {
    await defaultStorage.setDriver(localForage.LOCALSTORAGE);
    await defaultStorage.setItem<T>(key, value);
  } catch (err) {
    await defaultStorage.clear();
    await defaultStorage.setItem<T>(key, value);
  }
};
