import { ChainId } from "../../../types/web3";

// TODO: Add all the chains
export const COLLLECTION_ADMIN_ADDRESSES: Partial<Record<string, string>> = {
  [ChainId.BSCTest]: "0xE4A78B1eeb3da3Eca90119189B79C29e1feE6115",
  [ChainId.BSC]: "0x6fFbA6391588e4EAa43636C2302E168e70aF532C",
  [ChainId.FTM]: "0x0FF663db1294213eC4454BDdd926c7A66683DdFa",
  [ChainId.ONE]: "0xcD9283160FDABA840DD72eB1FDBC9e263B42CfE1",
  [ChainId.MATIC]: "0x38EbAF6E181e0e96Fd6bCBA5cA29EfFCe3D12d64",
  [ChainId.AVAX]: "0x0091788F5D663B6b0c26f56eC45Fd693BcA48db7",
};

export const ROYALTY_REGISTRY_ADDRESSES: Partial<Record<string, string>> = {
  [ChainId.BSCTest]: "0x73bE0eD6E3E4033586510394bE8d6219D8Baf812",
  [ChainId.BSC]: "0xEC113A3C0AbcA219C8564601ABD04fe65A8D0ce8",
  [ChainId.FTM]: "0x1dF1dDf2B4Db32d52F527E8dEcD393ABdE409806",
  [ChainId.ONE]: "0x1F80ad27E3DE89d81B566D79A10e9Ee89c77a452",
  [ChainId.MATIC]: "0x353b1f6aD0c8e887c52B402DA5053Ced6097212b",
  [ChainId.AVAX]: "0xDb0Cc237886f0dBa7990fA5CbdF720234805bA06",
};

export const MARKET_SETTINGS_ADDRESSES: Partial<Record<string, string>> = {
  [ChainId.BSCTest]: "0x7598E043309f16b82C7b0CDD55986D30C27Eb034",
  [ChainId.BSC]: "0xD4F17EcEf345ABB9A4cd0CCbf9F561506c31AD89",
  [ChainId.FTM]: "0x96014Ec422F92B1f7d5257531fB1B3e1a988F6b6",
  [ChainId.ONE]: "0xEEd59e3a17683378dd979e08e729A92a9dFc00a9",
  [ChainId.MATIC]: "0x67452F7a27403B685Bf081Df581dB3eEa3FD31A0",
  [ChainId.AVAX]: "0x5cDEEf8b59592DFC423A083358A38f4DBCFcc784",
};

export const ERC721_LISTINGS_V3: Partial<Record<string, string>> = {
  [ChainId.BSCTest]: "0xAAdB186e9D602C585139077a13C98fF248217be5",
  [ChainId.BSC]: "0x60cA6B50aB268644f1aD15b40ADdAE95048bff69",
  [ChainId.FTM]: "0xb9Fb7C0c96C95D5A454a5A703e02cd96Cb63Ba4D",
  [ChainId.ONE]: "0xE91C8A0FCBA134e687B180C1597C4b468AE6dAE6",
  [ChainId.MATIC]: "0x45c98916d85e9325C9266e9BFad04629172baEF7",
  [ChainId.AVAX]: "0xE2BDb71983054167476f4C8e440409e7fB8B82Cd",
};

export const ERC721_BIDS_V3: Partial<Record<string, string>> = {
  [ChainId.BSCTest]: "0x2bba168F28e5D6D0b709DEBf8cA1Fec0EEf9fD5c",
  [ChainId.BSC]: "0x877ea4e2352Be0eA6f68186906521352D14f432b",
  [ChainId.FTM]: "0xee77664801BC334B254deF7a92de44B260A3e61E",
  [ChainId.ONE]: "0xDBA19e577038e06835CDd7494874d23536Fd977a",
  [ChainId.MATIC]: "0xE869a0D3C1B3B66EBf889b025d13980c39eC5646",
  [ChainId.AVAX]: "0x6A70852e0834B6ff11AEc90a59Fb714ed4035Fa5",
};

export const ERC721_GLOBAL_OFFER_V3: Partial<Record<string, string>> = {
  [ChainId.BSCTest]: "0x9618081569DDd617a4bb9Ed593F3F4a1beF4098D",
  [ChainId.BSC]: "0x892Ce42E955EFeF9Bd240400bee8937eB0183E13",
  [ChainId.FTM]: "0x14B2171253f904CB699f86421F9060CDE653b8A6",
  [ChainId.ONE]: "0x95AE4E3Ef6D44507A0343884bF711ED8362Bc3e0",
  [ChainId.MATIC]: "0x1386B27002a4edD5e3E9B5ff05220A53Ee60C420",
  [ChainId.AVAX]: "0xa8F40416c67a7BE14EAe0ae05951Bc006Dc30561",
};

export const getMarketV3AddressesByChainId = (chainId?: ChainId) => {
  if (chainId) {
    return {
      listings: ERC721_LISTINGS_V3[chainId],
      bids: ERC721_BIDS_V3[chainId],
      globalOffer: ERC721_GLOBAL_OFFER_V3[chainId],
    };
  }

  return {
    listings: undefined,
    bids: undefined,
    globalOffer: undefined,
  };
};
