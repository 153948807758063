import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import fetch from "isomorphic-fetch";

export const createApolloClient = (uri?: string) => {
  const link = createHttpLink({
    fetch,
    uri: uri || "https://nftkey.app/api",
  });

  return new ApolloClient({
    cache: new InMemoryCache(),
    link,
    defaultOptions: {
      watchQuery: {
        nextFetchPolicy: "cache-first",
      },
    },
  });
};
