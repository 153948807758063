import * as React from "react";
import Web3 from "web3";
import { ConnectionErrorType, WalletName } from "../../../types/wallet";

interface WalletInfo {
  loading: boolean;
  isConnected: boolean;
  walletName?: WalletName;
  chainId?: number;
  web3?: Web3;
  userAccount?: string;
  userBalance?: number;
  paymentTokenBalance?: number;
  updateUserBalance?: () => Promise<void>;
  provider?: any;
}

export interface WalletLoading extends WalletInfo {
  loading: true;
  isConnected: false;
}

export interface WalletNotConnected extends WalletInfo {
  loading: false;
  isConnected: false;
  connectionErrorType: ConnectionErrorType;
  connectWallet: (
    connectWalletName: WalletName,
    connectChainId?: number,
    callback?: () => void
  ) => Promise<void>;
}

export interface WalletConnected extends WalletInfo {
  loading: false;
  isConnected: true;
  userAccount: string;
  web3: Web3;
  provider?: any;
  providerType?: string;
  disconnectWallet: () => void;
}

export type WalletContext =
  | WalletLoading
  | WalletNotConnected
  | WalletConnected;

export const defaultContextValue: WalletContext = {
  loading: true,
  isConnected: false,
};

export const WalletContext =
  React.createContext<WalletContext>(defaultContextValue);
