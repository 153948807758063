export enum Colors {
  White = "#ffffff",
  OffWhite = "#F3F5FB",
  Black = "#000000",
  Charcoal = "#303030",
  BorderGrey = "#dadde2",
  DeepGrey = "#686868",
  MidGrey = "#888888",
  LightGrey = "#E3E6EE",

  DarkModeBlack = "#0E0E0E",
  DarkModeBodyBlack = "#1A1A1A",
  DarkModeOffWhite = "#D1D1D1",
  DarkModeOffBlack = "#282828",
  DarkModeDeepGrey = "#3D3D3D",

  ShadowBlue = "#435686",

  Orange = "#FF7F37",
  Green = "#00B88C",
  Red = "#F40B27",
  Blue = "#0066FF",
  MoonYellow = "#F3BA2F",

  WrappedPunk = "#5DA47B",
  BNBBunnies = "#dde4ef",
  BioShapeShiftBackground = "#092a53",
}

export const BIO_SHAPE_SHIFT_COLORS = [
  "#df3c22",
  "#ee6a36",
  "#f5aa6f",
  // "#f3daae",
  "#84aba1",
  "#2a8896",
  "#1d6c8e",
];

export const BBB_COLORS: Record<number, string> = {
  10: "#feeaab",
  9: "#d5cafe",
  8: "#ffc7c7",
  7: "#abf0d5",
  6: "#c6d1fd",
  5: "#fbcae5",
  4: "#b5d8fe",
  3: "#d1d5da",
};

export const BBG_COLORS: Record<number, string> = {
  10: "#d1d5da",
  9: "#d1d5da",
  8: "#d1d5da",
  7: "#d1d5da",
  6: "#d1d5da",
  5: "#d1d5da",
  4: "#b5d8fe",
  3: "#d1d5da",
};
