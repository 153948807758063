const createSessionName = (flagName: string) => {
  return `nftkey-feature-${flagName}`;
};

const readAndPersistFlag = (
  flagName: string,
  searchParams: URLSearchParams
): boolean => {
  const sessionFlagName = createSessionName(flagName);
  try {
    if (!searchParams.has(flagName)) {
      const sessionParamValue =
        sessionStorage.getItem(sessionFlagName) === "true";
      return sessionParamValue;
    }

    const queryParamValue = searchParams.get(flagName) === "true";
    const flagValue = queryParamValue;
    sessionStorage.setItem(sessionFlagName, flagValue.toString());
    return flagValue;
  } catch (err) {
    return false;
  }
};

export interface FeaturesConfig {
  enableGodMode: boolean;
  enableBubbleMap: boolean;
  enableV3: boolean;
  enableCart: boolean;
}

export const emptyFeatureConfig: FeaturesConfig = {
  enableGodMode: false,
  enableBubbleMap: true,
  enableV3: true,
  enableCart: true,
};

export function loadFeatureConfig() {
  const url = new URL(window.location.href);

  const featuresConfig = emptyFeatureConfig;

  if (typeof window !== undefined) {
    featuresConfig.enableGodMode = readAndPersistFlag(
      "enableGodMode",
      url.searchParams
    );
    featuresConfig.enableBubbleMap = readAndPersistFlag(
      "enableBubbleMap",
      url.searchParams
    );
    // featuresConfig.enableCart = readAndPersistFlag(
    //   "enableCart",
    //   url.searchParams
    // );
    // featuresConfig.enableV3 = readAndPersistFlag("enableV3", url.searchParams);
  }

  return featuresConfig;
}
