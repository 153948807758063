import Web3 from "web3";
import { AbiItem } from "web3-utils";
import { ChainId } from "../../../../types/web3";
import {
  MARKET_SETTINGS_ADDRESSES,
  ERC721_LISTINGS_V3,
  ERC721_BIDS_V3,
  ERC721_GLOBAL_OFFER_V3,
} from "../../../constants/addresses/marketplace-addresses-v3";
import MarketSettingsABI from "../../../contracts/marketplace-v3/MarketSettingsABI.json";
import ERC721ListingsABI from "../../../contracts/marketplace-v3/ERC721ListingsABI.json";
import ERC721BidsABI from "../../../contracts/marketplace-v3/ERC721BidsABI.json";
import ERC721GlobalOfferABI from "../../../contracts/marketplace-v3/ERC721GlobalOfferABI.json";

interface MarketplaceAddressInput {
  chainId: ChainId;
}

export const getMarketSettingsV3Address = ({
  chainId,
}: MarketplaceAddressInput) => {
  return MARKET_SETTINGS_ADDRESSES[chainId];
};

export const getERC721ListingsV3Address = ({
  chainId,
}: MarketplaceAddressInput) => {
  return ERC721_LISTINGS_V3[chainId];
};

export const getERC721BidsV3Address = ({
  chainId,
}: MarketplaceAddressInput) => {
  return ERC721_BIDS_V3[chainId];
};

export const getERC721GlobalOfferV3Address = ({
  chainId,
}: MarketplaceAddressInput) => {
  return ERC721_GLOBAL_OFFER_V3[chainId];
};

interface MarketplaceContractInput {
  contractAddress: string;
  web3: Web3;
}

export const getMarketSettingsV3Contract = ({
  web3,
  contractAddress,
}: MarketplaceContractInput) => {
  return new web3.eth.Contract(MarketSettingsABI as AbiItem[], contractAddress);
};

export const getERC721ListingsV3Contract = ({
  web3,
  contractAddress,
}: MarketplaceContractInput) => {
  return new web3.eth.Contract(ERC721ListingsABI as AbiItem[], contractAddress);
};

export const getERC721BidsV3Contract = ({
  web3,
  contractAddress,
}: MarketplaceContractInput) => {
  return new web3.eth.Contract(ERC721BidsABI as AbiItem[], contractAddress);
};

export const getERC721GlobalOfferV3Contract = ({
  web3,
  contractAddress,
}: MarketplaceContractInput) => {
  return new web3.eth.Contract(
    ERC721GlobalOfferABI as AbiItem[],
    contractAddress
  );
};
