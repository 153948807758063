import { BlockchainInfo, ChainId } from "../../types/web3";
import { ChainLogos } from "../images/images";

export const SUPPORTED_CHAIN_IDS_V2 = [
  ChainId.FTM,
  ChainId.BSC,
  ChainId.MATIC,
  ChainId.ONE,
  ChainId.AVAX,
  ChainId.ETH,
];

export const SUPPORTED_CHAIN_IDS_V3 = [
  ChainId.FTM,
  ChainId.BSC,
  ChainId.MATIC,
  ChainId.ONE,
  ChainId.AVAX,
];

export const BLOCKCHAIN_INFO: Record<ChainId, BlockchainInfo> = {
  [ChainId.ETH]: {
    name: "Ethereum Mainnet",
    symbol: "ETH",
    shortName: "ETH",
    icon: {
      black: ChainLogos.EthLogoBlack,
      white: ChainLogos.EthLogoWhite,
      color: ChainLogos.EthLogoColor,
    },
    logoUrl:
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/info/logo.png",
    explorerUrl: "https://etherscan.io/",
    chainId: ChainId.ETH,
  },
  [ChainId.Ropsten]: {
    name: "Ropsten Test Network",
    symbol: "rETH",
    shortName: "Ropsten",
    icon: {
      black: ChainLogos.EthLogoBlack,
      white: ChainLogos.EthLogoWhite,
      color: ChainLogos.EthLogoColor,
    },
    logoUrl:
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/info/logo.png",
    explorerUrl: "https://ropsten.etherscan.io/",
    chainId: ChainId.Ropsten,
  },
  [ChainId.BSC]: {
    name: "BNB Smart Chain",
    symbol: "BNB",
    shortName: "BSC",
    icon: {
      black: ChainLogos.BscLogoBlack,
      white: ChainLogos.BscLogoWhite,
      color: ChainLogos.BscLogoColor,
    },
    logoUrl:
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/binance/info/logo.png",
    explorerUrl: "https://bscscan.com/",
    chainId: ChainId.BSC,
    rpcUrls: ["https://bsc-dataseed.binance.org/"],
  },
  [ChainId.BSCTest]: {
    name: "BSC Testnet",
    symbol: "BNB",
    shortName: "tBSC",
    icon: {
      black: ChainLogos.BscLogoBlack,
      white: ChainLogos.BscLogoWhite,
      color: ChainLogos.BscLogoColor,
    },
    logoUrl:
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/binance/info/logo.png",
    explorerUrl: "https://testnet.bscscan.com/",
    chainId: ChainId.BSCTest,
    rpcUrls: ["https://data-seed-prebsc-1-s2.binance.org:8545/"],
  },
  [ChainId.MATIC]: {
    name: "Polygon Network",
    symbol: "MATIC",
    shortName: "MATIC",
    icon: {
      black: ChainLogos.PolygonLogoBlack,
      white: ChainLogos.PolygonLogoWhite,
      color: ChainLogos.PolygonLogoColor,
    },
    logoUrl:
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/polygon/info/logo.png",
    explorerUrl: "https://polygonscan.com/",
    chainId: ChainId.MATIC,
    rpcUrls: [
      "https://polygon-rpc.com/",
      // "https://rpc.ankr.com/polygon",
    ],
  },
  [ChainId.FTM]: {
    name: "Fantom Mainnet",
    symbol: "FTM",
    shortName: "FTM",
    icon: {
      black: ChainLogos.FtmLogoBlack,
      white: ChainLogos.FtmLogoWhite,
      color: ChainLogos.FtmLogoColor,
    },
    logoUrl:
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/fantom/info/logo.png",
    explorerUrl: "https://ftmscan.com/",
    chainId: ChainId.FTM,
    rpcUrls: [
      "https://rpc.ftm.tools",
      // "https://rpc.ankr.com/fantom",
    ],
  },
  [ChainId.AVAX]: {
    name: "Avalanche Mainnet",
    symbol: "AVAX",
    shortName: "AVAX",
    icon: {
      black: ChainLogos.AvaxLogoBlack,
      white: ChainLogos.AvaxLogoWhite,
      color: ChainLogos.AvaxLogoColor,
    },
    logoUrl:
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/avalanchec/info/logo.png",
    explorerUrl: "https://snowtrace.io/",
    chainId: ChainId.AVAX,
    rpcUrls: ["https://avalanche-c-chain.publicnode.com"],
  },
  [ChainId.ONE]: {
    name: "Harmony Blockchain",
    symbol: "ONE",
    shortName: "ONE",
    icon: {
      black: ChainLogos.OneLogoBlack,
      white: ChainLogos.OneLogoWhite,
      color: ChainLogos.OneLogoColor,
    },
    logoUrl:
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/harmony/info/logo.png",
    explorerUrl: "https://explorer.harmony.one/",
    chainId: ChainId.ONE,
    rpcUrls: ["https://api.harmony.one"],
  },
};
