export const UrlParam = {
  chainId: "chainId",
  Life: {
    bioDNA: "bioDNA",
    bioId: "bioId",
  },
  Punk: {
    punkType: "punkType",
    punkAttributes: "punkAttributes",
    punkAttributesCount: "punkAttributesCount",
  },
  ERC721: {
    alias: "alias",
    tokenAddress: "tokenAddress",
    tokenId: "tokenId",
    transfer: "transfer",
  },
  sorter: {
    sorterType: "sorterType",
    sortDirection: "sortDirection",
  },
  filter: {
    traitFilters: "traitFilters",
    minRarity: "minRarity",
    maxRarity: "maxRarity",
  },
  UserNFTs: {
    address: "address",
    activeCollection: "activeCollection",
  },
  toggles: {
    myNFTsOnly: "myNFTsOnly",
    isMintingOnly: "isMintingOnly",
  },
};
