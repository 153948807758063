import { persistStorage } from "./local-storage/local-storages";

export const checkDarkMode = async (): Promise<boolean> => {
  try {
    const isDarkModePreferredInHistory = await persistStorage.getItem<boolean>(
      "isDarkMode"
    );

    if (isDarkModePreferredInHistory) {
      return true;
    }
  } catch (error) {}

  return false;
};

export const storeDarkModePreference = async (isDarkMode: boolean) => {
  try {
    await persistStorage.setItem<boolean>("isDarkMode", isDarkMode);
  } catch (error) {}
};
