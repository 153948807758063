import { ChainId } from "../../../types/web3";
import { getChainInfo } from "../blockchain-info";

export const switchChainInMetamask = (chainId: number) => {
  const { name, symbol, logoUrl, explorerUrl, rpcUrls } = getChainInfo(chainId);

  // Desktop and MetaMask!
  if (window.ethereum && window.ethereum.isMetaMask) {
    if (chainId !== ChainId.ETH && chainId !== ChainId.Ropsten) {
      // @ts-ignore
      ethereum
        .request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: `0x${chainId.toString(16)}`,
              chainName: name,
              rpcUrls,
              iconUrls: [logoUrl],
              blockExplorerUrls: [explorerUrl],
              nativeCurrency: {
                name: symbol,
                symbol: symbol,
                decimals: 18,
              },
            },
          ],
        })
        .then(() => {})
        .catch((error: any) => {
          if (error.code === 4001) {
            console.log("We can encrypt anything without the key.");
          } else {
            console.error(error);
          }
        });
    } else {
      // @ts-ignore
      ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: `0x${chainId.toString(16)}` }],
      });
    }
  }
};
