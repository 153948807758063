export interface EventDataTrimed {
  returnValues: {
    [key: string]: any;
  };
  event: string;
  logIndex: number;
  transactionHash: string;
  blockNumber: number;
}

export enum ChainId {
  ETH = 1,
  Ropsten = 3,
  BSC = 56,
  BSCTest = 97,
  MATIC = 137,
  FTM = 250,
  AVAX = 43114,
  ONE = 1666600000,
}

export interface BlockchainInfo {
  name: string;
  symbol: string;
  shortName: string;
  icon: { black: string; white: string; color: string };
  logoUrl: string;
  explorerUrl: string;
  rpcUrls?: string[];
  chainId: ChainId;
}
