import { Global, ThemeProvider } from "@emotion/react";
import * as React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { throttle } from "throttle-debounce";
import {
  darkTheme,
  lightTheme,
} from "../components/ui-library/design-tokens/theme";
import { checkDarkMode, storeDarkModePreference } from "../utils/dark-mode";
import {
  emptyFeatureConfig,
  FeaturesConfig,
  loadFeatureConfig,
} from "../utils/features-config";
import { SiteContext } from "./site-context";
import { globalStyle, toastStyle } from "./site.styles";

interface SiteProviderProps {
  children?: React.ReactNode;
}

const SiteProvider: React.FC<SiteProviderProps> = ({ children }) => {
  const [isMobile, setMobile] = React.useState<boolean>(true);
  const [isDarkMode, setDarkmode] = React.useState<boolean>(false);
  const [featureConfigs, setFeatureConfig] =
    React.useState<FeaturesConfig>(emptyFeatureConfig);

  React.useEffect(() => {
    if (window) {
      const checkDeviceSize = () => {
        const windowSize = window.innerWidth;
        if (windowSize < 767) {
          setMobile(true);
        } else {
          setMobile(false);
        }
      };
      checkDeviceSize();
      window.addEventListener("resize", throttle(500, checkDeviceSize));

      checkDarkMode().then((isDarkModePreferred) => {
        setDarkmode(isDarkModePreferred);
      });

      setFeatureConfig(loadFeatureConfig());
    }
  }, []);

  const store = {
    isMobile,
    isDarkMode,
    featureConfigs,
    setDarkmode: (isDarkModePreferred: boolean) => {
      setDarkmode(isDarkModePreferred);
      storeDarkModePreference(isDarkModePreferred);
    },
  };

  const theme = isDarkMode ? darkTheme : lightTheme;

  return (
    <SiteContext.Provider value={store}>
      <ThemeProvider theme={theme}>
        <Global styles={globalStyle(theme)} />

        {children}

        <ToastContainer
          position={toast.POSITION.BOTTOM_RIGHT}
          theme={isDarkMode ? "dark" : "light"}
          css={toastStyle}
        />
      </ThemeProvider>
    </SiteContext.Provider>
  );
};

export default SiteProvider;
