import * as React from "react";
import { CartItem } from "../../../types/shopping-cart";
import { getERC721ListingsV3Address } from "../../utils/erc721-marketplace/marketplace-v3/marketplace-v3-contract";
import { checkTokensForPurchase } from "../../utils/erc721-marketplace/marketplace-v3/v3-info/check-tokens-for-purchase";
import {
  getDefaultStorageItem,
  setDefaultStorageItem,
} from "../../utils/local-storage/local-storages";
import { WalletContext } from "../wallet-context/wallet-context";
import { CartContext } from "./cart-context";

interface CartProviderProps {
  children?: React.ReactNode;
}

const removeFromCart = (
  items: CartItem[],
  item: { collectionId: string; tokenId: string }
) => {
  return items.filter(
    (i) => !(i.collectionId === item.collectionId && i.tokenId === item.tokenId)
  );
};

const CartProvider: React.FC<CartProviderProps> = ({ children }) => {
  const { web3, chainId, userAccount } = React.useContext(WalletContext);

  const [loading, setLoading] = React.useState<boolean>(true);
  const [items, setItems] = React.useState<CartItem[]>([]);

  React.useEffect(() => {
    getDefaultStorageItem<CartItem[]>("shoppingCart").then((storedItems) => {
      setItems(storedItems || []);
      setLoading(false);
    });
  }, []);

  const updateCart = (newItems: CartItem[]) => {
    setItems(newItems);
    setDefaultStorageItem<CartItem[]>("shoppingCart", newItems);
  };

  const performCartCheck = async () => {
    if (web3 && userAccount && chainId) {
      const listingsV3Address = getERC721ListingsV3Address({ chainId });
      if (listingsV3Address) {
        setLoading(true);
        const checkResult = await checkTokensForPurchase({
          contractAddress: listingsV3Address,
          web3,
          tokens: items,
          buyer: userAccount,
          chainId,
        });
        updateCart(checkResult);
        setLoading(false);
        return checkResult;
      }
    }
  };

  const store = {
    loading,
    items,
    updateCart,
    addToCart: (item: CartItem) =>
      updateCart([...removeFromCart(items, item), item]),
    removeFromCart: (item: { collectionId: string; tokenId: string }) =>
      updateCart(removeFromCart(items, item)),
    performCartCheck,
  };

  return <CartContext.Provider value={store}>{children}</CartContext.Provider>;
};

export default CartProvider;
