import * as React from "react";
import Web3 from "web3";
import { ChainId } from "../../types/web3";
import { getPaymentTokenAllowance } from "../utils/erc721-marketplace/erc20-token-info/get-payment-token-allowance";
import { getPaymentTokenBalance } from "../utils/erc721-marketplace/erc20-token-info/get-payment-token-balance";
import { getDataWeb3ByChainId } from "../utils/web3/data-feed-web3";
import { getBalance } from "../utils/web3/wallet-balance";

interface WalletBalanceInput {
  userAccount?: string;
  web3?: Web3;
  chainId?: ChainId;
}

export const useWalletBalance = ({
  userAccount,
  web3,
  chainId,
}: WalletBalanceInput) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [userBalance, setUserBalance] = React.useState<number | undefined>();
  const [paymentTokenBalance, setPaymentTokenBalance] = React.useState<
    number | undefined
  >();

  const dataWeb3 = getDataWeb3ByChainId({ chainId });
  const web3ToUse = web3 || dataWeb3;

  const getUserBalance = async () => {
    setLoading(true);
    if (userAccount && web3ToUse && chainId !== undefined) {
      const balance = await getBalance({ web3: web3ToUse, userAccount });
      if (balance !== null) {
        setUserBalance(balance.ethBalance);
      } else {
        setUserBalance(undefined);
      }

      const paymentBalance = await getPaymentTokenBalance({
        web3: web3ToUse,
        userAccount,
        chainId,
      });
      if (paymentBalance !== null) {
        setPaymentTokenBalance(paymentBalance);
      } else {
        setPaymentTokenBalance(undefined);
      }
    }
    setLoading(false);
  };

  React.useEffect(() => {
    getUserBalance();
  }, [web3, userAccount, chainId]);

  return {
    loading,
    userBalance,
    paymentTokenBalance,
    updateUserBalance: getUserBalance,
  };
};

interface PaymentTokenAllowanceByContractsInput {
  userAccount?: string;
  contracts: string[];
  web3?: Web3;
  chainId?: ChainId;
}

export const usePaymentTokenAllowanceByContracts = ({
  userAccount,
  contracts,
  web3,
  chainId,
}: PaymentTokenAllowanceByContractsInput) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [paymentTokenAllowances, setPaymentTokenAllowances] = React.useState<
    Partial<Record<string, number>>
  >({});

  const getUserAllowance = async (contract: string) => {
    if (userAccount && web3 && chainId !== undefined) {
      const paymentAllowance = await getPaymentTokenAllowance({
        operatorAddress: contract,
        web3,
        userAccount,
        chainId,
      });
      return paymentAllowance;
    }
  };

  const getUserAllowances = async () => {
    setLoading(true);
    const allowances: Partial<Record<string, number>> = {};
    const promises = [];

    for (const contract of contracts) {
      const getAllowance = async () => {
        const allowance = await getUserAllowance(contract);
        if (allowance !== null) {
          allowances[contract] = allowance;
        }
      };
      promises.push(getAllowance());
    }
    await Promise.all(promises);
    setPaymentTokenAllowances(allowances);
    setLoading(false);
  };

  React.useEffect(() => {
    getUserAllowances();
  }, [userAccount, contracts.join(), chainId]);

  return {
    loading,
    paymentTokenAllowances,
    updateTokenAllowance: getUserAllowances,
  };
};
