import { Theme } from "@emotion/react";
import { transparentize } from "polished";
import { Colors } from "./colors";

export const lightTheme: Theme = {
  isDark: false,
  foreground: Colors.Black,
  text: Colors.DeepGrey,
  background: Colors.White,
  bodyBackground: Colors.OffWhite,
  secondaryBackground: Colors.LightGrey,
  border: Colors.BorderGrey,
  shadow: transparentize(0.9, Colors.ShadowBlue),
  shadowDeep: transparentize(0.75, Colors.ShadowBlue),
};

export const darkTheme: Theme = {
  isDark: true,
  foreground: Colors.White,
  text: Colors.DarkModeOffWhite,
  background: Colors.DarkModeOffBlack,
  bodyBackground: Colors.DarkModeBodyBlack,
  secondaryBackground: Colors.DarkModeBlack,
  border: Colors.DarkModeBlack,
  shadow: transparentize(0.7, Colors.Black),
  shadowDeep: transparentize(0.4, Colors.Black),
};
