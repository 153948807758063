exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-collections-life-bsc-bio-player-tsx": () => import("./../../../src/pages/collections/life/bsc/bio-player.tsx" /* webpackChunkName: "component---src-pages-collections-life-bsc-bio-player-tsx" */),
  "component---src-pages-collections-life-bsc-index-tsx": () => import("./../../../src/pages/collections/life/bsc/index.tsx" /* webpackChunkName: "component---src-pages-collections-life-bsc-index-tsx" */),
  "component---src-pages-collections-life-bsc-token-details-tsx": () => import("./../../../src/pages/collections/life/bsc/token-details.tsx" /* webpackChunkName: "component---src-pages-collections-life-bsc-token-details-tsx" */),
  "component---src-pages-collections-life-design-lab-tsx": () => import("./../../../src/pages/collections/life/design-lab.tsx" /* webpackChunkName: "component---src-pages-collections-life-design-lab-tsx" */),
  "component---src-pages-collections-life-eth-bio-player-tsx": () => import("./../../../src/pages/collections/life/eth/bio-player.tsx" /* webpackChunkName: "component---src-pages-collections-life-eth-bio-player-tsx" */),
  "component---src-pages-collections-life-eth-index-tsx": () => import("./../../../src/pages/collections/life/eth/index.tsx" /* webpackChunkName: "component---src-pages-collections-life-eth-index-tsx" */),
  "component---src-pages-collections-life-eth-token-details-tsx": () => import("./../../../src/pages/collections/life/eth/token-details.tsx" /* webpackChunkName: "component---src-pages-collections-life-eth-token-details-tsx" */),
  "component---src-pages-collections-life-start-tsx": () => import("./../../../src/pages/collections/life/start.tsx" /* webpackChunkName: "component---src-pages-collections-life-start-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

