import Web3 from "web3";
import detectEthereumProvider from "@metamask/detect-provider";
import WalletConnectProvider from "@walletconnect/web3-provider";
import WalletLink from "walletlink";
import { ConnectionErrorType, WalletName } from "../../../types/wallet";
import {
  getDefaultStorageItem,
  setDefaultStorageItem,
} from "../local-storage/local-storages";
import { RPC_PROVIDER } from "./data-feed-web3";
import { switchChainInMetamask } from "./switch-chain";

const getInjectedProvider = async () => {
  const provider = await detectEthereumProvider();

  if (!provider) {
    return {
      provider: null,
      errorType: "noInjectedProvider" as ConnectionErrorType,
    };
  }

  if (provider !== window.ethereum) {
    return {
      provider: null,
      errorType: "multipleWallet" as ConnectionErrorType,
    };
  }

  return {
    provider,
    isMetaMask: !!provider.isMetaMask as boolean,
  };
};

export const getInjectedWeb3 = async () => {
  const { provider, isMetaMask, errorType } = await getInjectedProvider();

  if (provider) {
    const web3 = new Web3(provider as any);
    return { provider, web3, isMetaMask };
  } else {
    if (window.web3) {
      return {
        web3: window.web3,
        isMetaMask: false,
      };
    }

    return { errorType, isMetaMask };
  }
};

const checkConnectionPreference = async () => {
  const isPreferred = await getDefaultStorageItem<boolean>(
    "preferWalletConnected"
  );
  return isPreferred || isPreferred === null ? true : false;
};

export const setConnectionPreference = (isConnectPreferred: boolean) =>
  setDefaultStorageItem("preferWalletConnected", isConnectPreferred);

const getChainId = async (web3: Web3): Promise<number | undefined> => {
  try {
    const chainId = await web3.eth.getChainId();
    return chainId;
  } catch (error) {
    return undefined;
  }
};

const getUserAccount = async (web3: Web3): Promise<string | undefined> => {
  try {
    const accounts = await web3.eth.getAccounts();

    if (accounts[0]) {
      return accounts[0];
    } else {
      return undefined;
    }
  } catch (error) {
    return undefined;
  }
};

export const getUserConnectionInfo = async (web3: Web3) => {
  const isConnectPreferred = await checkConnectionPreference();
  const chainId = await getChainId(web3);
  const account = await getUserAccount(web3);

  return {
    chainId,
    account: isConnectPreferred ? account : undefined,
  };
};

interface ConnectionInfoOutput {
  account?: string;
  chainId?: number;
  provider: any;
  web3: Web3;
}

const connectMetaMask = async (
  connectChainId?: number
): Promise<ConnectionInfoOutput | null> => {
  const provider: any = window.ethereum;

  if (!provider) {
    return null;
  }

  try {
    await provider.request({
      method: "eth_requestAccounts",
    });

    await setConnectionPreference(true);

    const web3 = new Web3(provider);
    const { account, chainId } = await getUserConnectionInfo(web3);

    if (connectChainId && chainId !== connectChainId) {
      await switchChainInMetamask(connectChainId);
      const { chainId: newChainId } = await getUserConnectionInfo(web3);
      return { provider, web3, chainId: newChainId, account };
    } else {
      return { provider, web3, chainId, account };
    }
  } catch (error) {
    return null;
  }
};

const connectWalletConnect = async (
  connectChainId: number
): Promise<ConnectionInfoOutput | null> => {
  await setConnectionPreference(true);

  try {
    //  Create WalletConnect Provider
    const provider: any = new WalletConnectProvider({
      infuraId: "24fc0fe66a1f41bfb30983aa3f498565",
      rpc: RPC_PROVIDER,
      qrcode: true,
      pollingInterval: 60000,
      chainId: connectChainId,
    });

    //  Enable session (triggers QR Code modal)
    await provider.enable();

    const web3 = new Web3(provider);
    const { account, chainId } = await getUserConnectionInfo(web3);

    return { provider, web3, account, chainId };
  } catch (error) {
    console.error("Error connect WalletConnect", error);
    return null;
  }
};

const connectWalletLink = async (
  connectChainId?: number,
  darkMode?: boolean
): Promise<ConnectionInfoOutput | null> => {
  await setConnectionPreference(true);

  try {
    const walletLink = new WalletLink({
      appName: "NFTKEY Marketplace",
      appLogoUrl: "https://cdn.nftkey.app/images/logos/nftkey-circle-256px.png",
      darkMode,
    });

    const provider = walletLink.makeWeb3Provider(
      RPC_PROVIDER[connectChainId || 1],
      connectChainId || 1
    );

    const web3 = new Web3(provider as any);

    //  Enable session (triggers QR Code modal)
    await provider.enable();

    const { account, chainId } = await getUserConnectionInfo(web3);

    return { provider, web3, account, chainId };
  } catch (error) {
    console.error("Error connect WalletLink", error);
    return null;
  }
};

export interface ConnectWalletInput {
  walletName: WalletName;
  connectChainId?: number;
  darkMode?: boolean;
}

export const connectWallet = async ({
  walletName,
  connectChainId,
  darkMode,
}: ConnectWalletInput): Promise<ConnectionInfoOutput | null> => {
  if (walletName === "MetaMask") {
    return connectMetaMask(connectChainId);
  }
  if (walletName === "WalletLink") {
    return connectWalletLink(connectChainId, darkMode);
  }
  if (walletName === "WalletConnect" && connectChainId) {
    return connectWalletConnect(connectChainId);
  }
  return null;
};

type AccountChangeCallback = (account?: string) => void;

export const watchAccountChange = (
  provider: any,
  callback: AccountChangeCallback
) => {
  if (provider) {
    try {
      provider.on("accountsChanged", (accounts: string[]) => {
        callback(accounts[0]);
      });
    } catch (error) {
      console.error("Failed watching account change", error);
    }
  }
};

type ChainChangeCallback = (chainId?: number) => void;

export const watchChainChange = (
  provider: any,
  callback: ChainChangeCallback
) => {
  if (provider) {
    try {
      provider.on("chainChanged", () => {
        const web3 = new Web3(provider);
        getChainId(web3).then((chainId) => {
          callback(chainId);
        });
      });
    } catch (error) {
      console.error("Failed watching chain change", error);
    }
  }
};
