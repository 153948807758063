export const Fonts = {
  Family:
    "Roboto, 'Helvetica Neue', HelveticaNeue, Helvetica, Arial, sans-serif, Noto Sans SC",
  FamilyMono: "Menlo, 'Courier New', Courier, Monospace",
  Weight: {
    Normal: 400,
    SemiBold: 500,
    Bold: 700,
  },
};
