import { ApolloProvider } from "@apollo/client";
import React from "react";
import WalletProvider from "./src/site-context/wallet-context/wallet-provider";
import SiteProvider from "./src/site-context/site-provider";
import CartProvider from "./src/site-context/cart-context/cart-provider";
import { createApolloClient } from "./src/utils/apollo/apollo-client";

export const wrapRootElement = ({ element }) => {
  return (
    <SiteProvider>
      <WalletProvider>
        <ApolloProvider client={createApolloClient()}>
          <CartProvider>{element}</CartProvider>
        </ApolloProvider>
      </WalletProvider>
    </SiteProvider>
  );
};
