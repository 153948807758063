import { BlockchainInfo, ChainId } from "../../types/web3";
import { BLOCKCHAIN_INFO } from "../constants/chain-info";
import { UrlParam } from "../constants/url-parameters";

export const getChainInfo = (chainId?: number) => {
  const allInfo: Record<number, BlockchainInfo | undefined> = BLOCKCHAIN_INFO;
  const chainInfo = chainId ? allInfo[chainId] : undefined;
  return chainInfo || BLOCKCHAIN_INFO[ChainId.ETH];
};

export const getUserNFTsUrl = (address: string) => {
  return `/address/?${UrlParam.UserNFTs.address}=${address}`;
};

export const getAddressExplorerUrl = (address: string, chainId?: number) => {
  return `${getChainInfo(chainId).explorerUrl}address/${address}`;
};

export const getTokenExplorerUrl = (
  address: string,
  chainId?: number,
  tokenId?: string
) => {
  if (chainId === ChainId.ONE && tokenId !== undefined) {
    return `${
      getChainInfo(chainId).explorerUrl
    }inventory/erc721/${address.toLowerCase()}/${tokenId}`;
  }

  if (chainId === ChainId.ONE) {
    return `${
      getChainInfo(chainId).explorerUrl
    }address/${address.toLowerCase()}`;
  }

  const param = tokenId !== undefined ? `?a=${tokenId}` : "";
  return `${getChainInfo(chainId).explorerUrl}token/${address}${param}`;
};

export const getTransactionExplorerUrl = (tsHash: string, chainId?: number) => {
  return `${getChainInfo(chainId).explorerUrl}tx/${tsHash}`;
};
