import Web3 from "web3";
import { AbiItem } from "web3-utils";
import { ChainId } from "../../../types/web3";
import WETH from "../../contracts/WETH.json";

// TODO: Add more chain
const ERC20_ADDRESSES_MAPPING: Record<number, string | undefined> = {
  [ChainId.BSC]: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c", // WBNB
  [ChainId.BSCTest]: "0xae13d989dac2f0debff460ac112a837c89baa7cd", // WBNB
  [ChainId.ETH]: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2", // WETH
  [ChainId.Ropsten]: "0xc778417E063141139Fce010982780140Aa0cD5Ab", // WETH
  [ChainId.MATIC]: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270", // WMATIC
  [ChainId.FTM]: "0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83", // WFTM
  [ChainId.AVAX]: "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7", // WAVAX
  [ChainId.ONE]: "0xcf664087a5bb0237a0bad6742852ec6c8d69a27a", // WONE
};

interface PaymentTokenContractInput {
  chainId: number;
  web3: Web3;
}

export const getPaymentTokenContract = ({
  web3,
  chainId,
}: PaymentTokenContractInput) => {
  const contractAddress = ERC20_ADDRESSES_MAPPING[chainId];

  if (contractAddress) {
    return new web3.eth.Contract(WETH as AbiItem[], contractAddress);
  }

  return null;
};

export const getPaymentTokenConvertingLink = (chainId?: number) => {
  let prefix = `https://app.uniswap.org/#/swap`;

  if (!chainId) {
    return prefix;
  }

  const contractAddress = ERC20_ADDRESSES_MAPPING[chainId];

  if (chainId === ChainId.BSC || chainId === ChainId.BSCTest) {
    prefix = `https://app.apeswap.finance/swap`;
  }

  if (chainId === ChainId.MATIC) {
    prefix = "https://quickswap.exchange/#/swap";
  }

  if (chainId === ChainId.FTM) {
    return `https://swap.spiritswap.finance/#/exchange/swap/WFTM/FTM`;
  }

  if (chainId === ChainId.AVAX) {
    prefix = "https://www.traderjoexyz.com/trade";
  }

  if (chainId === ChainId.ONE) {
    prefix = "https://viperswap.one/#/swap";
  }

  return `${prefix}?outputCurrency=${contractAddress}`;
};
