import * as React from "react";
import Web3 from "web3";
import { ConnectionErrorType, WalletName } from "../../../types/wallet";
import { useWalletBalance } from "../../hooks/use-wallet-balance";
import {
  connectWallet,
  getInjectedWeb3,
  watchAccountChange,
  getUserConnectionInfo,
  watchChainChange,
  setConnectionPreference,
} from "../../utils/web3/wallets-helper";
import { SiteContext } from "../site-context";
import {
  WalletConnected,
  WalletContext,
  WalletLoading,
  WalletNotConnected,
} from "./wallet-context";

interface WalletProviderProps {
  children?: React.ReactNode;
}

const WalletProvider: React.FC<WalletProviderProps> = ({ children }) => {
  const { isDarkMode } = React.useContext(SiteContext);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [isConnected, setConnected] = React.useState<boolean>(false);
  const [walletName, setWalletName] = React.useState<WalletName>();
  const [connectionErrorType, setConnectionErrorType] =
    React.useState<ConnectionErrorType>("noInjectedProvider");
  const [chainId, setChainId] = React.useState<number>();
  const [userAccount, setUserAccount] = React.useState<string>();
  const [web3, setWeb3] = React.useState<Web3>();
  const [provider, setProvider] = React.useState<any>();

  const setupState = async () => {
    const injectedWeb3 = await getInjectedWeb3();

    if (injectedWeb3) {
      setProvider(injectedWeb3.provider);
      setWalletName(injectedWeb3.isMetaMask ? "MetaMask" : undefined);
      setWeb3(injectedWeb3.web3);
      if (injectedWeb3.web3) {
        const connectionInfo = await getUserConnectionInfo(injectedWeb3.web3);
        setChainId(connectionInfo.chainId);
        if (connectionInfo.account) {
          setUserAccount(connectionInfo.account);
          setConnected(true);
        } else {
          setConnected(false);
          setConnectionErrorType("noAccount");
        }
      }

      if (injectedWeb3.errorType) {
        setConnectionErrorType(injectedWeb3.errorType);
      }
    }
    setLoading(false);
  };

  React.useEffect(() => {
    setupState();
  }, []);

  React.useEffect(() => {
    if (provider) {
      watchAccountChange(provider, (newAccount) => {
        if (newAccount) {
          setUserAccount(newAccount);
          setConnected(true);
        } else {
          setConnected(false);
          setConnectionErrorType("noAccount");
        }
      });

      watchChainChange(provider, (newChainId) => {
        if (newChainId) {
          setChainId(newChainId);
        }
      });
    }
  }, [provider]);

  const handleConnectWallet = async (
    connectWalletName: WalletName,
    connectChainId?: number,
    callback?: () => void
  ) => {
    const data = await connectWallet({
      walletName: connectWalletName,
      connectChainId,
      darkMode: isDarkMode,
    });

    if (data) {
      setWalletName(walletName);
      setProvider(data.provider);
      setWeb3(data.web3);

      if (data.account) {
        setUserAccount(data.account);
        setChainId(data.chainId);
        setConnected(true);
        if (callback) {
          callback();
        }
      } else {
        setConnected(false);
        setConnectionErrorType("noAccount");
      }
    } else {
      setConnected(false);
      setConnectionErrorType("noInjectedProvider");
    }
  };

  const handleDisconnectWallet = async () => {
    await setConnectionPreference(false);
    setConnected(false);
    setUserAccount(undefined);
    setConnectionErrorType("noAccount");
    if (provider) {
      if (provider.disconnect) {
        provider.disconnect();
      } else if (provider.close) {
        provider.close();
      }
      setConnectionErrorType("noInjectedProvider");
    }
  };

  const storeLoading: WalletLoading = { loading: true, isConnected: false };

  const storeNotConnected: WalletNotConnected = {
    loading: false,
    isConnected: false,
    walletName,
    chainId,
    web3,
    provider,
    connectionErrorType,
    connectWallet: handleConnectWallet,
  };

  const { userBalance, paymentTokenBalance, updateUserBalance } =
    useWalletBalance({
      userAccount,
      web3,
      chainId,
    });

  const storeConnected: WalletConnected = {
    loading: false,
    isConnected: true,
    walletName,
    chainId,
    userAccount: userAccount!,
    web3: web3!,
    userBalance,
    paymentTokenBalance,
    updateUserBalance,
    provider,
    disconnectWallet: handleDisconnectWallet,
  };

  const store = loading
    ? storeLoading
    : !isConnected
    ? storeNotConnected
    : storeConnected;

  return (
    <WalletContext.Provider value={store}>{children}</WalletContext.Provider>
  );
};

export default WalletProvider;
